import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthenticationService } from 'src/app/modules/authentication/services/authentication.service';
import { BaseComponent } from 'src/app/modules/core/components/base/base.component';
import { CouponCustomersDetailsDto } from 'src/app/modules/core/models/coupons/couponCustomersDetailsDto';
import { UseCouponResponse } from 'src/app/modules/core/models/coupons/useCouponResponse';
import { BroadcasterService } from 'src/app/modules/core/services/broadcaster.service';
import { CouponsService } from 'src/app/modules/coupons/services/coupons.service';

@Component({
  selector: 'app-use-coupon-dialog',
  templateUrl: './use-coupon-dialog.component.html',
  styleUrls: ['./use-coupon-dialog.component.scss'],
})
export class UseCouponDialogComponent extends BaseComponent implements OnInit {
  details!: CouponCustomersDetailsDto;
  constructor(
    broadcastService: BroadcasterService,
    authService: AuthenticationService,
    public couponsService: CouponsService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private confirmationService: ConfirmationService
  ) {
    super(broadcastService, authService);
    this.details = config.data.details;
  }
  useCoupon() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'Use coupon',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.couponsService
          .useCoupon(
            this.details.customerCoupon!.couponCustomersId,
            this.details.userName
          )
          .subscribe(() => {
            this.ref.close({
              isUsed: true,
              isUnAssigned: false,
              details: this.details,
            } as UseCouponResponse);
          });
      },
      reject: () => {},
    });
  }
  unAssignCoupon() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'UnAssign coupon',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.couponsService
          .unAssignCoupon(
            this.details.customerCoupon!.coupon.id,
            this.details.userName
          )
          .subscribe(() => {
            this.ref.close({
              isUsed: false,
              isUnAssigned: true,
              details: this.details,
            } as UseCouponResponse);
          });
      },
      reject: () => {},
    });
  }
  closeDialog() {
    this.ref.close();
  }
}
