import { Pipe, PipeTransform } from '@angular/core';
import { getCountryCallingCodeFor } from '../../core/utils/phone';

@Pipe({
  name: 'countryCodeCountryCallingCode',
})
export class CountryCodeCountryCallingCodePipe implements PipeTransform {
  transform(code: string|undefined): string {
    return getCountryCallingCodeFor(code);
  }
}
