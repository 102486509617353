import { Component, Input, OnInit, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    },
  ],
})
export class ColorPickerComponent implements ControlValueAccessor, OnInit {
  @Input() formControl!: FormControl;

  color: string = '#FFFFFF'; // Default color

  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};
  private disabled: boolean = false; // To handle disabled state

  ngOnInit(): void {
    // Initialize color from the form control's value
    if (this.formControl?.value) {
      this.color = this.formControl.value;
    }
  }

  writeValue(value: string): void {
    this.color = value || '#FFFFFF';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onColorChange(event: any): void {
    this.color = event.value; // Update color from the color picker
    this.updateValue();
  }

  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    if (this.isValidHex(value)) {
      this.color = value;
      this.updateValue();
    }
  }

  private updateValue(): void {
    if (this.onChange) {
      this.onChange(this.color); // Notify parent form of value change
    }
    if (this.formControl) {
      this.formControl.setValue(this.color, { emitEvent: true });
    }
    this.onTouched();
  }

  private isValidHex(value?: string): boolean {
    return !!value && /^#([0-9A-Fa-f]{6})$/.test(value);
  }
}
