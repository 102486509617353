import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';


export type UrlSegment ='home' | 'users' | 'coupons'| 'stampcards';

@Injectable({
  providedIn: 'root',
})
export class RouteResolverService {
  public activeUrlSegment!: UrlSegment;
  isParent: boolean = false;
  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        var segments = event.url.split('/');
        if (segments.length == 2) {
          this.isParent = true;
          if (segments[1] != '') {
            this.activeUrlSegment = segments[1];
          } else {
            this.activeUrlSegment = 'home';
          }
        } else if (segments.length > 2) {
          this.isParent = false;
        }
      });
  }
}
