import { Pipe, PipeTransform } from '@angular/core';
import { timeDate, TimeDateOption } from '../../core/utils/date-options';

type Options = 'date' | 'time' | 'date-expand' | 'date-time';

@Pipe({
  name: 'timeDate',
})
export class TimeDatePipe implements PipeTransform {
  transform(value: string | Date, options?: Options): string {
    return timeDate(value, options as TimeDateOption);
  }
}
