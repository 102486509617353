import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivateChild {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    var authUser = this.authService.authUser;
    var routeSegment = state.url.split('/')[1];
    if (authUser == null) {
      this.router.navigate(['/auth/login']);
      return false;
    }
    if (authUser != null && !authUser.tenantId && !authUser.branchId) {
      this.router.navigate(['/settings']);
      return false;
    }
    if (!this.authService.isRouteAccessible(routeSegment)) {
      this.router.navigate(['/home']);
      return false;
    }
    return true;
  }
}
