import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CouponListItemDto } from '../../core/models/coupons/couponListItemDto';
import { CreateCouponRequest } from '../../core/models/coupons/createCouponRequest';
import { CreateCouponResponse } from '../../core/models/coupons/createCouponResponse';
import { CouponsDetailsDto } from '../../core/models/coupons/couponsDetailsDto';
import { UpdateCouponDetailsRequest } from '../../core/models/coupons/updateCouponDetailsRequest';
import { CouponCustomersDetailsDto } from '../../core/models/coupons/couponCustomersDetailsDto';
import { CustomerActiveCouponDetailsDto } from '../../core/customers/customerActiveCouponDetailsDto';
import { CouponTypeEnum } from '../../core/models/coupons/couponTypeEnum';
import { DuplicateCouponResponse } from '../../core/models/coupons/duplicateCouponResponse';

@Injectable({
  providedIn: 'root',
})
export class CouponsService {
  constructor(private http: HttpClient) {}
  public getCouponFromActiveCoupon(
    activeCoupon: CustomerActiveCouponDetailsDto
  ): CouponsDetailsDto {
    return {
      ...activeCoupon.coupon,
      expirationDate: activeCoupon.couponExpiresOn,
    };
  }
  getCoupons(
    type?: CouponTypeEnum,
    isExpired?: boolean,
    isPublished?: boolean,
    name?: string,
    isDeleted?: boolean
  ): Observable<CouponListItemDto[]> {
    var queryStrings = '?';
    if (type != undefined) {
      queryStrings = queryStrings + `type=${type}&`;
    }
    if (isExpired != undefined) {
      queryStrings = queryStrings + `isExpired=${isExpired}&`;
    }
    if (isPublished != undefined) {
      queryStrings = queryStrings + `isPublished=${isPublished}&`;
    }
    if (name) {
      queryStrings = queryStrings + `name=${name}&`;
    }
    if (isDeleted != undefined) {
      queryStrings = queryStrings + `isDeleted=${isDeleted}&`;
    }
    return this.http.get<CouponListItemDto[]>(`/coupons${queryStrings}`);
  }
  getCouponsWithDetails(
    type?: CouponTypeEnum,
    isExpired?: boolean,
    isPublished?: boolean,
    name?: string,
    isDeleted?: boolean
  ): Observable<CouponsDetailsDto[]> {
    var queryStrings = '?';
    if (type != undefined) {
      queryStrings = queryStrings + `type=${type}&`;
    }
    if (isExpired != undefined) {
      queryStrings = queryStrings + `isExpired=${isExpired}&`;
    }
    if (isPublished != undefined) {
      queryStrings = queryStrings + `isPublished=${isPublished}&`;
    }
    if (name) {
      queryStrings = queryStrings + `name=${name}&`;
    }
    if (isDeleted != undefined) {
      queryStrings = queryStrings + `isDeleted=${isDeleted}&`;
    }
    return this.http.get<CouponsDetailsDto[]>(
      `/coupons/details${queryStrings}`
    );
  }
  getCouponDetails(id: string): Observable<CouponsDetailsDto> {
    return this.http.get<CouponsDetailsDto>(`/coupons/${id}`);
  }
  publishCoupon(id: string): Observable<any> {
    return this.http.put<any>(`/coupons/${id}/publish`, null);
  }
  duplicateCoupon(id: string): Observable<DuplicateCouponResponse> {
    return this.http.post<DuplicateCouponResponse>(
      `/coupons/${id}/duplicate`,
      null
    );
  }
  getCustomerCoupon(
    couponCustomersId: string,
    customerUserName: string
  ): Observable<CouponCustomersDetailsDto> {
    return this.http.get<CouponCustomersDetailsDto>(
      `/coupons/use?couponCustomersId=${couponCustomersId}&customerUserName=${customerUserName}`
    );
  }
  assignCoupon(
    couponId: string,
    customerUserName: string
  ): Observable<CustomerActiveCouponDetailsDto> {
    return this.http.post<CustomerActiveCouponDetailsDto>(
      `/coupons/${couponId}/assign?customerUserName=${customerUserName}`,
      null
    );
  }
  unAssignCoupon(couponId: string, customerUserName: string): Observable<any> {
    return this.http.delete<any>(
      `/coupons/${couponId}/assign?customerUserName=${customerUserName}`
    );
  }
  useCoupon(
    couponCustomersId: string,
    customerUserName: string
  ): Observable<any> {
    return this.http.put<any>(
      `/coupons/use?couponCustomersId=${couponCustomersId}&customerUserName=${customerUserName}`,
      null
    );
  }
  deleteCoupon(id: string): Observable<any> {
    return this.http.delete<any>(`/coupons/${id}`);
  }
  createCoupon(request: CreateCouponRequest): Observable<CreateCouponResponse> {
    const formData: FormData = new FormData();
    formData.append('name', request.name);
    formData.append('title', request.title);
    formData.append('description', request.description);
    formData.append('termsAndConditions', request.termsAndConditions);
    if (request.expirationDate != null) {
      formData.append('expirationDate', request.expirationDate);
    }
    formData.append('couponType', request.couponType.toString());
    if (request.backgroundColorHex != null) {
      formData.append('backgroundColorHex', request.backgroundColorHex);
    }
    if (request.backgroundImage != null) {
      formData.append('backgroundImage', request.backgroundImage);
    }
    if (request.uploadedCouponIcon != null) {
      formData.append('uploadedCouponIcon', request.uploadedCouponIcon);
    }
    if (request.couponIconId != null) {
      formData.append('couponIconId', request.couponIconId);
    }
    if (request.iconText) {
      formData.append('iconText', request.iconText);
    }

    formData.append('titleTextColorHex', request.titleTextColorHex);
    formData.append('descriptionTextColorHex', request.descriptionTextColorHex);
    formData.append('expirationTextColorHex', request.expirationTextColorHex);
    formData.append('discountTextColorHex', request.discountTextColorHex);

    return this.http.post<CreateCouponResponse>(`/coupons`, formData);
  }
  updateCoupon(
    couponId: string,
    request: UpdateCouponDetailsRequest
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('name', request.name);
    formData.append('title', request.title);
    formData.append('description', request.description);
    formData.append('termsAndConditions', request.termsAndConditions);
    if (request.expirationDate != null) {
      formData.append('expirationDate', request.expirationDate);
    }
    formData.append('couponType', request.couponType.toString());
    if (request.backgroundColorHex != null) {
      formData.append('backgroundColorHex', request.backgroundColorHex);
    }
    formData.append(
      'currentBackgroundImageRemoved',
      request.currentBackgroundImageRemoved.toString()
    );
    if (request.backgroundImage != null) {
      formData.append('backgroundImage', request.backgroundImage);
    }
    if (request.uploadedCouponIcon != null) {
      formData.append('uploadedCouponIcon', request.uploadedCouponIcon);
    }
    if (request.couponIconId != null) {
      formData.append('couponIconId', request.couponIconId);
    }
    formData.append(
      'uploadedCouponIconRemoved',
      request.uploadedCouponIconRemoved.toString()
    );
    if (request.iconText) {
      formData.append('iconText', request.iconText);
    }

    formData.append('titleTextColorHex', request.titleTextColorHex);
    formData.append('descriptionTextColorHex', request.descriptionTextColorHex);
    formData.append('expirationTextColorHex', request.expirationTextColorHex);
    formData.append('discountTextColorHex', request.discountTextColorHex);
    return this.http.put<any>(`/coupons/${couponId}`, formData);
  }
}
