import { Pipe, PipeTransform } from '@angular/core';
import { timeDate } from '../../core/utils/date-options';

@Pipe({
  name: 'couponExpireOrNever'
})
export class CouponExpireOrNeverPipe implements PipeTransform {

  transform(value: Date|undefined|null, ...args: unknown[]):string {
    return (value==undefined || value==null)?'No Expiration':'Valid till : '+timeDate(value,'date-expand');
  }

}
