import { Pipe, PipeTransform } from '@angular/core';
import { CouponTypeEnum } from '../../core/models/coupons/couponTypeEnum';

@Pipe({
  name: 'couponTypeToString',
})
export class CouponTypeToStringPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    // Convert the numeric value to the corresponding string label from the enum
    switch (value) {
      case CouponTypeEnum.OnlyAdminIssues:
        return 'Only Admin Issues';
      case CouponTypeEnum.Public:
        return 'Public';
      case CouponTypeEnum.TargetCustomers:
        return 'Target Customers';
      case CouponTypeEnum.StampCard:
        return 'Stamp Card';
      default:
        return 'Unknown'; // Handle invalid or unknown values
    }
  }
}
