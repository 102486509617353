<div
  class="stamp-card-container"
  style="position: relative; overflow: hidden"
  *ngIf="stampCard"
  [ngStyle]="{
    'background-color': stampCard.cardBackgroundColorHex
      ? stampCard.cardBackgroundColorHex
      : 'white'
  }"
>
  <!-- Background Image as img tag -->
  <img
    *ngIf="stampCard.cardBackgroundImageUrl"
    [src]="stampCard.cardBackgroundImageUrl"
    alt="Card Background Image"
    class="background-image"
  />
  <div
    class="w-full flex justify-content-end align-items-center"
    style="position: relative; z-index: 1"
  >
    <p
      class="text-sm mb-3"
      [style]="{ color: stampCard.expirationDateColorHex }"
    >
      {{ getExpirationDate() | couponExpireOrNever }}
    </p>
  </div>
  <div class="w-full flex flex-column align-items-center">
    <img
      *ngIf="stampCard.showCompanyLogo"
      [src]="stampCard.uploadedCompanyLogoUrl ?? 'assets/images/company.png'"
      alt="Logo"
      class="mb-2 stamp-company-logo"
    />
    <div class="w-full flex align-items-center justify-content-center mb-4">
      <div
        class="flex flex-column justify-content-center align-items-center mt-2 mb-2"
        style="position: relative; z-index: 1"
      >
        <p
          class="font-bold text-center mb-0 text-lg"
          [style]="{ color: stampCard.cardTitleColorHex }"
        >
          {{ stampCard.title == "" ? "TITLE" : stampCard.title }}
        </p>
      </div>
    </div>
  </div>

  <div class="stamp-card-content w-full">
    <div
      *ngFor="let row of splitIntoRows(stamps, 4)"
      class="stamps-row gap-3 w-full flex"
      [ngClass]="{
        'justify-content-between': row.length === 4,
        'justify-content-start': row.length < 4
      }"
    >
      <div class="stamps" *ngFor="let stamp of row">
        <ng-container *ngIf="stamp.isUsed">
          <div *ngIf="stampCard.stampIconUrl; else fallbackStamp">
            <i
              class="pi pi-circle relative"
              [ngStyle]="{
                'background-color': stampCard.emptyStampBackgroundColorHex,
                color: stampCard.stampBorderColorHex
              }"
            >
              <img
                [src]="stampCard.stampIconUrl"
                alt="Stamp Icon"
                class="stamp-icon"
              />
            </i>
          </div>

          <ng-template #fallbackStamp>
            <i
              class="pi pi-circle relative"
              [ngStyle]="{
                'background-color': stampCard.emptyStampBackgroundColorHex,
                color: stampCard.stampBorderColorHex
              }"
            >
              <i
                class="pi pi-circle"
                class="stamp-icon"
                [ngStyle]="{
                  'background-color': stampCard.usedStampBackgroundColorHex,
                  color: stampCard.stampBorderColorHex
                }"
              ></i>
            </i>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="!stamp.isUsed">
          <i
            class="pi pi-circle"
            [ngStyle]="{
              'background-color': stampCard.emptyStampBackgroundColorHex,
              color: stampCard.stampBorderColorHex
            }"
          ></i>
        </ng-container>
      </div>
    </div>
  </div>
  <div
    *ngIf="stampCard.showCompanyName"
    class="w-full flex justify-content-center mt-4 mb-2"
  >
    <p
      class="m-0 text-sm company-name text-center"
      [style]="{ color: stampCard.companyNameColorHex }"
    >
      {{ stampCard.companyName }}
    </p>
  </div>
</div>
