import { USerRole } from '../models/users/userRole';

export type UserRoles = 'superadmin' | 'admin' | 'manager' | 'employees';
export const userRolesList: USerRole[] = [
  {
    role: 1,
    name: 'superadmin',
  },
  {
    role: 2,
    name: 'admin',
  },
  {
    role: 3,
    name: 'manager',
  },
  {
    role: 4,
    name: 'employees',
  }
];
