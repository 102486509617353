import {
  Component,
  Input,
  SimpleChanges,
  OnChanges,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-custom-carousal',
  templateUrl: './custom-carousal.component.html',
  styleUrls: ['./custom-carousal.component.scss'],
})
export class CustomCarousalComponent implements OnChanges {
  @Input() items: any[] = []; // The list of items to display
  @Input() template!: TemplateRef<any>; // The template to render each item
  @Input() activeItemStyle?:string='';
  currentIndex = 0;

  ngOnChanges(changes: SimpleChanges): void {
    // If the items array changes, reset the currentIndex
    if (
      changes['items'] &&
      changes['items'].currentValue !== changes['items'].previousValue
    ) {
      this.resetCarousel();
    }
  }
  resetCarousel() {
    this.currentIndex = 0;
  }
  // Navigate to the next slide
  next() {
    this.currentIndex = (this.currentIndex + 1) % this.items.length;
  }

  // Navigate to the previous slide
  prev() {
    this.currentIndex =
      (this.currentIndex - 1 + this.items.length) % this.items.length;
  }

  // Set slide based on dot navigation
  setSlide(index: number) {
    this.currentIndex = index;
  }
}
