import { UserRoles } from './UserRoles';

export interface RouteDescription {
  label: FeatureLabel;
  icon: string;
  routerLink: string[];
  allowedUsers?: UserRoles[];
}

export type FeatureLabel = 'Home'|'Users'|'Coupons'|'StampCards';

export const featurePriorities: FeatureLabel[] = ['Home','Coupons','StampCards'];

export var routeDescriptions = (): RouteDescription[] => [
  {
    label: 'Home',
    icon: 'pi pi-home',
    routerLink: ['/home'],
  },
  {
    label: 'Users',
    icon: 'pi pi-user',
    routerLink: ['/users'],
  },
  {
    label: 'Coupons',
    icon: 'pi pi-ticket',
    routerLink: ['/coupons'],
  },
  {
    label: 'StampCards',
    icon: 'pi pi-star',
    routerLink: ['/stamp-cards'],
  },
];
