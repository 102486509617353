import { Component, Input } from '@angular/core';
import { CouponsDetailsDto } from 'src/app/modules/core/models/coupons/couponsDetailsDto';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
})
export class CouponComponent {
  @Input() coupon!: CouponsDetailsDto; // Coupon input
}
