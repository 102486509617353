import {
  CountryCode,
  getCountryCallingCode,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';

export function getCountryCallingCodeFor(
  countryCode: string | undefined
): string {
  if (!countryCode) {
    return 'nill';
  }
  const callingCode = getCountryCallingCode(countryCode as CountryCode);
  return callingCode ? `+${callingCode}` : 'nil';
}
export function getValidPhoneNumber(
  phoneNumber: string,
  countryCode: string | undefined
): string | undefined {
  if (!countryCode) {
    return countryCode;
  }
  const phoneNumberInstance = parsePhoneNumberFromString(
    phoneNumber,
    countryCode as CountryCode
  );

  if (phoneNumberInstance && phoneNumberInstance.isValid()) {
    // Format the phone number in international format (e.g., +123456789)
    return phoneNumberInstance.formatInternational().replace(/\s+/g, '');
  } else {
    // Invalid phone number
    return undefined;
  }
}
