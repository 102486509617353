import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { RouteDescription } from '../modules/core/constants/routeDescriptions';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app.sidebar.component.html',
})
export class AppSidebarComponent {
  timeout: any = null;

  @Input() tenantLogoUrl?: string;
  @Input() rewardoFeatures!: RouteDescription[];

  @ViewChild('menuContainer') menuContainer!: ElementRef;
  constructor(public layoutService: LayoutService, public el: ElementRef) {}

  @Output() openQRScanDialog: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  onMouseEnter() {
    if (!this.layoutService.state.anchored) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.layoutService.state.sidebarActive = true;
    }
  }

  onMouseLeave() {
    if (!this.layoutService.state.anchored) {
      if (!this.timeout) {
        this.timeout = setTimeout(
          () => (this.layoutService.state.sidebarActive = false),
          300
        );
      }
    }
  }
  qrScanDialog() {
    this.openQRScanDialog.emit(true);
  }
  anchor() {
    this.layoutService.state.anchored = !this.layoutService.state.anchored;
  }
}
