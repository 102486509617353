import { Component, Input } from '@angular/core';
import { addDays } from 'date-fns';
import { StampCard } from 'src/app/modules/core/models/stampcards/stampCard';

@Component({
  selector: 'app-stamp-card',
  templateUrl: './stamp-card.component.html',
  styleUrls: ['./stamp-card.component.scss'],
})
export class StampCardComponent {
  @Input() stampCard!: StampCard; // Input to receive the stamp card data from parent
  @Input() stamps: any[] = []; // Input to receive stamps array (used and empty)

  getExpirationDate() {
    return this.stampCard.expireCardAfterRecivedInDays
      ? addDays(new Date(), this.stampCard.expireCardAfterRecivedInDays)
      : null;
  }
  splitIntoRows(stamps: any[], rowSize: number): any[][] {
    const rows = [];
    for (let i = 0; i < stamps.length; i += rowSize) {
      rows.push(stamps.slice(i, i + rowSize));
    }
    return rows;
  }
  
}
