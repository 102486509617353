import {
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { MenuService } from './app.menu.service';
import { AppSidebarComponent } from './app.sidebar.component';
import { AppTopbarComponent } from './app.topbar.component';
import { LayoutService } from './service/app.layout.service';
import { BaseComponent } from '../modules/core/components/base/base.component';
import { AuthenticationService } from '../modules/authentication/services/authentication.service';
import { BroadcasterService } from '../modules/core/services/broadcaster.service';
import { ConfirmationService } from 'primeng/api';
import { RouteResolverService } from './service/route-resolver.service';
import {
  FeatureLabel,
  RouteDescription,
  routeDescriptions,
} from '../modules/core/constants/routeDescriptions';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ScanQrDialogComponent } from '../modules/shared/components/scan-qr-dialog/scan-qr-dialog.component';
import { StampStampcardDialogComponent } from '../modules/shared/components/stamp-stampcard-dialog/stamp-stampcard-dialog.component';
import { UseCouponDialogComponent } from '../modules/shared/components/use-coupon-dialog/use-coupon-dialog.component';

@Component({
  selector: 'app-layout',
  templateUrl: './app.layout.component.html',
  providers: [DialogService, ConfirmationService],
})
export class AppLayoutComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  overlayMenuOpenSubscription: Subscription;
  topbarClasses:string='';
  wrapperClasses:string='';
  menuOutsideClickListener: any;

  menuScrollListener: any;

  @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

  @ViewChild(AppTopbarComponent) appTopbar!: AppTopbarComponent;

  qrScanDialog: DynamicDialogRef | undefined;
  stampStampCardDialog: DynamicDialogRef | undefined;
  useCouponDialog: DynamicDialogRef | undefined;

  constructor(
    authService: AuthenticationService,
    broadcastService: BroadcasterService,
    private menuService: MenuService,
    public layoutService: LayoutService,
    public renderer: Renderer2,
    public router: Router,
    public route: RouteResolverService,
    private activeRoute: ActivatedRoute,
    public dialogService: DialogService,
  ) {
    super(broadcastService, authService);
    this.overlayMenuOpenSubscription =
      this.layoutService.overlayOpen$.subscribe(() => {
        if (!this.menuOutsideClickListener) {
          this.menuOutsideClickListener = this.renderer.listen(
            'document',
            'click',
            (event) => {
              const isOutsideClicked = !(
                this.appSidebar.el.nativeElement.isSameNode(event.target) ||
                this.appSidebar.el.nativeElement.contains(event.target) ||
                this.appTopbar.menuButton.nativeElement.isSameNode(
                  event.target
                ) ||
                this.appTopbar.menuButton.nativeElement.contains(event.target)
              );
              if (isOutsideClicked) {
                this.hideMenu();
              }
            }
          );
        }

        if (
          (this.layoutService.isHorizontal() ||
            this.layoutService.isSlim() ||
            this.layoutService.isSlimPlus()) &&
          !this.menuScrollListener
        ) {
          this.menuScrollListener = this.renderer.listen(
            this.appSidebar.menuContainer.nativeElement,
            'scroll',
            (event) => {
              if (this.layoutService.isDesktop()) {
                this.hideMenu();
              }
            }
          );
        }

        if (this.layoutService.state.staticMenuMobileActive) {
          this.blockBodyScroll();
        }
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.hideMenu();
      });
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.activeRoute.data.subscribe((data) => {
      this.topbarClasses = data['topbarClasses']??'';
      this.wrapperClasses = data['wrapperClasses']??'';
    });
    var allowedFeatures = routeDescriptions();
    // var allowedFeatures = this.authService.filterFeatures(
    //   routeDescriptions().sort((a, b) => {
    //     if (a.label < b.label) {
    //       return -1;
    //     }
    //     if (a.label > b.label) {
    //       return 1;
    //     }
    //     return 0;
    //   })
    // );
    let labelsToMoveToStart: FeatureLabel[] = ['Home'];
    var tenantAllowedFeatures = allowedFeatures.sort((a, b) => {
      let aIndex = labelsToMoveToStart.indexOf(a.label);
      let bIndex = labelsToMoveToStart.indexOf(b.label);

      if (aIndex === -1 && bIndex === -1) {
        return 0; // No need to change order if both labels are not in labelsToMoveToStart
      } else if (aIndex === -1) {
        return 1; // Move b to the start if a is not in labelsToMoveToStart
      } else if (bIndex === -1) {
        return -1; // Move a to the start if b is not in labelsToMoveToStart
      } else {
        return aIndex - bIndex; // Preserve the order within labelsToMoveToStart
      }
    });
    var filteredByRouteDefGuards: RouteDescription[] = [];
    tenantAllowedFeatures.forEach((route) => {
      if (!route.allowedUsers) {
        filteredByRouteDefGuards.push(route);
      } else if (
        route.allowedUsers &&
        this.authService.authUser!.userRoles.find((r) =>
          route.allowedUsers!.includes(r)
        )
      ) {
        filteredByRouteDefGuards.push(route);
      }
    });
    this.authService.rewardoFeatures = filteredByRouteDefGuards;
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          '(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)',
          'gi'
        ),
        ' '
      );
    }
  }

  hideMenu() {
    this.layoutService.state.overlayMenuActive = false;
    this.layoutService.state.staticMenuMobileActive = false;
    this.layoutService.state.menuHoverActive = false;
    this.menuService.reset();

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
      this.menuOutsideClickListener = null;
    }

    if (this.menuScrollListener) {
      this.menuScrollListener();
      this.menuScrollListener = null;
    }

    this.unblockBodyScroll();
  }

  get containerClass() {
    return {
      'layout-light': this.layoutService.config.colorScheme === 'light',
      'layout-dim': this.layoutService.config.colorScheme === 'dim',
      'layout-dark': this.layoutService.config.colorScheme === 'dark',
      'layout-colorscheme-menu':
        this.layoutService.config.menuTheme === 'colorScheme',
      'layout-primarycolor-menu':
        this.layoutService.config.menuTheme === 'primaryColor',
      'layout-transparent-menu':
        this.layoutService.config.menuTheme === 'transparent',
      'layout-overlay': this.layoutService.config.menuMode === 'overlay',
      'layout-static': this.layoutService.config.menuMode === 'static',
      'layout-slim': this.layoutService.config.menuMode === 'slim',
      'layout-slim-plus': this.layoutService.config.menuMode === 'slim-plus',
      'layout-horizontal': this.layoutService.config.menuMode === 'horizontal',
      'layout-reveal': this.layoutService.config.menuMode === 'reveal',
      'layout-drawer': this.layoutService.config.menuMode === 'drawer',
      'layout-static-inactive':
        this.layoutService.state.staticMenuDesktopInactive &&
        this.layoutService.config.menuMode === 'static',
      'layout-overlay-active': this.layoutService.state.overlayMenuActive,
      'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
      'p-input-filled': this.layoutService.config.inputStyle === 'filled',
      'p-ripple-disabled': !this.layoutService.config.ripple,
      'layout-sidebar-active': this.layoutService.state.sidebarActive,
      'layout-sidebar-anchored': this.layoutService.state.anchored,
    };
  }
  signOut() {
    this.authService.logoutUser();
  }
  navigateToProfile() {
    this.router.navigate(['/settings/profile']);
  }
  navigateToUpdateProfile() {
    this.router.navigate(['/settings']);
  }
  navigateToTenant() {
    this.router.navigate(['/tenant']);
  }
  navigateTo(url: string) {
    this.router.navigate([url]);
  }

  openQRScanDialog() {
    this.qrScanDialog = this.dialogService.open(ScanQrDialogComponent, {
      showHeader: false,
      styleClass: 'w-12 lg:w-6   p-0',
      contentStyle: {
        'background-color': 'var(--surface-ground)',
        padding: 0,
      },
    });
    this.qrScanDialog.onClose.subscribe((res: any) => {
      if (res) {
        if (res.coupon) {
          this.useCouponDialog=this.dialogService.open(
            UseCouponDialogComponent,
            {
              showHeader: false,
              styleClass: 'w-12 lg:w-6 h-screen p-0',
              contentStyle: {
                'background-color': 'var(--surface-ground)',
              },
              data: {
                details: res.coupon,
              },
            }
          );
        } else if (res.stampCard) {
          this.stampStampCardDialog = this.dialogService.open(
            StampStampcardDialogComponent,
            {
              header: 'Stamp card',
              showHeader: false,
              styleClass: 'w-12 lg:w-6 h-screen p-0',
              contentStyle: {
                'background-color': 'var(--surface-ground)',
              },
              data: {
                details: res.stampCard,
              },
            }
          );
        }
      }
    });
  }

  override ngOnDestroy() {
    if (this.qrScanDialog) {
      this.qrScanDialog.close();
    }
    if (this.stampStampCardDialog) {
      this.stampStampCardDialog.close();
    }
    if (this.useCouponDialog) {
      this.useCouponDialog.close();
    }
    if (this.overlayMenuOpenSubscription) {
      this.overlayMenuOpenSubscription.unsubscribe();
    }

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
    }
  }
}
