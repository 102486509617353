import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthenticationService } from 'src/app/modules/authentication/services/authentication.service';
import { BaseComponent } from 'src/app/modules/core/components/base/base.component';
import { StampCard } from 'src/app/modules/core/models/stampcards/stampCard';
import { StampCardDetailsDto } from 'src/app/modules/core/models/stampcards/stampCardDetailsDto';
import { StampCardsCustomersDetailsDto } from 'src/app/modules/core/models/stampcards/stampCardsCustomersDetailsDto';
import { StampStampCardDialogResponse } from 'src/app/modules/core/models/stampcards/stampStampCardDialogResponse';
import { BroadcasterService } from 'src/app/modules/core/services/broadcaster.service';
import { StampcardsService } from 'src/app/modules/stamp-cards/services/stampcards.service';

@Component({
  selector: 'app-stamp-stampcard-dialog',
  templateUrl: './stamp-stampcard-dialog.component.html',
  styleUrls: ['./stamp-stampcard-dialog.component.scss'],
})
export class StampStampcardDialogComponent
  extends BaseComponent
  implements OnInit
{
  details!: StampCardsCustomersDetailsDto;
  constructor(
    broadcastService: BroadcasterService,
    authService: AuthenticationService,
    public stampcardsService: StampcardsService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private confirmationService: ConfirmationService
  ) {
    super(broadcastService, authService);
    this.details = config.data.details;
  }
  mapStampCardDetailsDtoToStampCard(dto: StampCardDetailsDto): StampCard {
    return this.stampcardsService.mapStampCardDetailsToPreview(
      dto,
      this.authUser.tenantLogoUrl
    );
  }
  generateStampsArray(
    totalStamps: number,
    usedStamps: number
  ): { isUsed: boolean }[] {
    return Array.from({ length: totalStamps }, (_, i) => ({
      isUsed: i < usedStamps,
    }));
  }
  closeDialog() {
    this.ref.close();
  }
  unAssignCard() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'UnAssign stampcard',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.stampcardsService
          .unAssignStampCard(
            this.details.customerStampCard!.stampCard!.id,
            this.details.userName
          )
          .subscribe(() => {
            this.ref.close({
              type: 'unassigned',
              details: this.details,
            } as StampStampCardDialogResponse);
          });
      },
      reject: () => {},
    });
  }
  stampCard() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'Stamp',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.stampcardsService
          .stampStampCard(
            this.details.customerStampCard!.stampCard!.id,
            this.details.userName
          )
          .subscribe((data) => {
            if (!data.coupon) {
              this.ref.close({
                type: 'stamped',
                details: this.details,
              } as StampStampCardDialogResponse);
            } else {
              this.ref.close({
                type: 'complete',
                coupon: data.coupon,
                details: this.details,
              } as StampStampCardDialogResponse);
            }
          });
      },
      reject: () => {},
    });
  }
  isFull() {
    return (
      this.details.customerStampCard!.usedSlots.length ==
      this.details.customerStampCard!.stampCard.stampCount
    );
  }
}
