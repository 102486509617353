<div class="layout-container" [ngClass]="containerClass">
  <app-sidebar
    [rewardoFeatures]="this.authService.rewardoFeatures"
    [tenantLogoUrl]="authUser.tenantLogoUrl"
    (openQRScanDialog)="openQRScanDialog()"
  ></app-sidebar>
  <div class="layout-content-wrapper" [class]="wrapperClasses">
    <app-topbar
      [class]="topbarClasses"
      [profileImage]="authUser.profilePicUrl"
    ></app-topbar>

    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-profilemenu
    (signoutEvent)="signOut()"
    (navigateToProfileEvent)="navigateToProfile()"
    (navigateToUpdateProfileEvent)="navigateToUpdateProfile()"
    (navigateToTenantEvent)="navigateToTenant()"
    (navigateToEvent)="navigateTo($event)"
    [authUser]="authUser"
    [rewardoFeatures]="this.authService.rewardoFeatures"
    (openQRScanDialog)="openQRScanDialog()"
  ></app-profilemenu>
  <div class="layout-mask"></div>
</div>

<div *ngIf="route.isParent" class="block w-full lg:hidden">
  <app-bottom-nav
    [rewardoFeatures]="this.authService.rewardoFeatures"
  ></app-bottom-nav>
</div>
<p-confirmDialog></p-confirmDialog>
