<div class="custom-color-picker">
  <!-- Color Picker -->
  <p-colorPicker
    [(ngModel)]="color"
    (onChange)="onColorChange($event)"
  ></p-colorPicker>

  <!-- Hex Input -->
  <input
    type="text"
    [value]="color"
    (input)="onInputChange($event)"
    placeholder="#FFFFFF"
    maxlength="7"
  />
</div>
